<template>
  <div class="chart-box">
    <mdb-doughnut-chart
      class="chart"
      ref="chartSalaryEmploye"
      :data="doughnutChartData"
      :options="doughnutChartOptions"
      :width="width"
      :height="height"
    ></mdb-doughnut-chart>
  </div>
</template>

<script>
import { mdbDoughnutChart } from 'mdbvue';
import 'chartjs-plugin-labels';
export default {
  props: {
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    label: {
      type: Array
    },
    data: {
      type: Array
    },
    datasets: {
      type: Object
    }
  },
  components: {
    mdbDoughnutChart
  },
  data() {
    return {
      doughnutChartData: {
        labels: this.label,
        datasets: [
          {
            data: this.data,
            backgroundColor: this.datasets.backgroundColor,
            hoverBackgroundColor: this.datasets.hoverBackgroundColor
          }
        ]
      },
      doughnutChartOptions: {
        responsive: false,
        legend: {
          display: false
        },
        plugins: {
          labels: {
            render: 'percentage',
            showActualPercentages: true,
            fontColor: 'white',
            arc: true,
            fontSize: 10
          }
        }
      }
    };
  },
  watch: {
    data: function() {
      this.doughnutChartData.labels = this.label;
      this.doughnutChartData.datasets[0]['data'] = this.data;
      this.$nextTick(() => {
        this.$refs['chartSalaryEmploye'].renderChart(
          this.doughnutChartData,
          this.doughnutChartOptions
        );
      });
    }
  }
};
</script>

<style></style>
